.App {
  display: grid;
  background-color: #f6f6ef;
}

.headerContainer {
  background-color: #ff6600;
  padding: 0.3em;
}
.bodyContainer {
}
.footerContainer {
  background-color: #ff6600;
  height: 2px;
  margin-bottom: 20px;
}
