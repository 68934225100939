.container {
  margin: 1em 1em;
  padding: 1em;
  list-style: none;

  display: grid;
  grid-template-columns: minmax(250px, 1fr);
  grid-gap: 1em;
  grid-auto-rows: 200px;
}

@media only screen and (min-width: 768px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
